import copy from 'copy-to-clipboard';
import React from 'react';
import { useLocation } from 'react-router-dom';

import shareIcon from '../../assets/share.svg';

const ShareIcon = () => {
  const location = useLocation();
  function copyLink() {
    const url = `${process.env.REACT_APP_URL}${location.pathname}`;

    if (navigator.share) {
      navigator
        .share({
          title: `Let's Point It! App`,
          text: `Join the pointing session: ${location.pathname}`,
          url,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      copy(url);
    }
  }

  return (
    <button className='share-button' onClick={copyLink} type='button'>
      <img src={shareIcon} alt='Share Link' />
    </button>
  );
};
export default ShareIcon;
