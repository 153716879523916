import { useEffect } from 'react';

import { anonUser } from '../firebase/firestore';

export const useAnonymousAuth = ({ firebaseAuth, setUser }) => {
  useEffect(() => {
    const listener = function getUser(userId) {
      anonUser(userId).onSnapshot((user) => {
        setUser(user.data());
      });
    };

    firebaseAuth.signInAnonymously().then((data) => {
      if (data.additionalUserInfo.isNewUser) {
        const userInfo = {
          id: data.user.uid,
          roomId: null,
        };
        anonUser(data.user.uid)
          .set(userInfo)
          .then(() => listener(data.user.uid));
      } else {
        listener(data.user.uid);
      }
    });

    return () => listener();
  }, [firebaseAuth, setUser]);
};
