import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';


import { firebaseConfig } from './config';

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const fs = firebase.firestore();

fs.enablePersistence().catch(function (err) {
  console.error(err);
});

export const anonUser = (uid) => fs.collection('users').doc(uid);

export const createNewSession = (session) =>
  fs.collection('sessions').add(session);

export const updateUserLocation = (userId, sessionId) =>
  fs.collection('users').doc(userId).update({ sessionId });

export const getSession = (id) => fs.collection('sessions').doc(id);

export const addUserToSession = (sessionId, user) =>
  fs
    .collection('sessions')
    .doc(sessionId)
    .update({ [`attendees.${user.id}`]: user });

export const updateUserInSession = (user, profile, session) => {
  const updatedUser = {
    ...user,
    ...profile,
    roomId: session.id,
  };
  const batch = fs.batch();

  const userRef = fs.collection('users').doc(user.id);
  batch.update(userRef, updatedUser);

  const sessionRef = fs.collection('sessions').doc(session.id);
  batch.update(sessionRef, { [`attendees.${user.id}`]: updatedUser });

  return batch.commit();
};

export const updatePointsForSession = (session, pointSystem) => {
  const cleanedPoints = pointSystem.filter((str) => {
    return /\S/.test(str.trim());
  });

  return fs
    .collection('sessions')
    .doc(session.id)
    .update({ pointSystem: cleanedPoints });
};

export const choosePointCard = (user, point, session) => {
  return fs
    .collection('sessions')
    .doc(session.id)
    .update({ [`attendees.${user.id}`]: { ...user, point } });
};

export const toggleHands = (session, toggleHandState) => {
  if (!toggleHandState) {
    const attendeeKeys = Object.keys(session.attendees);
    const updatedAttendees = {};
    attendeeKeys.map(
      (attendeeKey) =>
        (updatedAttendees[attendeeKey] = {
          ...session.attendees[attendeeKey],
          point: null,
        })
    );
    const updatedSession = {
      ...session,
      attendees: updatedAttendees,
      displayHands: toggleHandState,
    };
    return fs.collection('sessions').doc(session.id).update(updatedSession);
  } else {
    return fs
      .collection('sessions')
      .doc(session.id)
      .update({ displayHands: toggleHandState });
  }
};
