import * as firebase from 'firebase/app';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import LandingPage from './components/landing/LandingPage';
import SessionPage from './components/session/SessionPage';
import { useAnonymousAuth } from './hooks/auth';
import { UserContext } from './hooks/user.context';

function App() {
  const firebaseAuth = firebase.auth();
  const [user, setUser] = useState(null);
  useAnonymousAuth({ firebaseAuth, setUser });

  return (
    <UserContext.Provider value={{ user }}>
      <Router>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/session/:sessionId' component={SessionPage} />
        </Switch>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
