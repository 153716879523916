import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/logo.svg';
import { createNewSession } from '../../firebase/firestore';

const LandingPage = () => {
  const textInput = useRef(null);
  const [error, setError] = useState({ sessionNameRequired: null });
  const history = useHistory();

  function createSession() {
    createNewSession({
      pointSystem: [1, 2, 3, 5, 8, 13, 21],
      attendees: {},
      displayHands: false,
    }).then((newRoom) => {
      history.push(`/session/${newRoom.id}`);
    });
  }

  function joinSession() {
    const sessionName = textInput.current.value;
    if (sessionName.trim() === '') {
      setError({
        sessionNameRequired: 'Session name is required.',
      });
    } else {
      setError({
        sessionNameRequired: null,
      });
      history.push(`/session/${sessionName}`);
    }
  }

  function onNameChanges() {
    if (textInput.current.value.trim() !== '') {
      setError({ sessionNameRequired: null });
    }
  }

  return (
    <div className='landing-page frame'>
      <div className='logo'>
        <img src={logo} alt={`Let's Point It!`} />
      </div>
      <div className='form'>
        <div>
          {!!error.sessionNameRequired && (
            <span className='error-message'>{error.sessionNameRequired}</span>
          )}
          <input
            type='text'
            placeholder='Session ID'
            ref={textInput}
            onChange={onNameChanges}
            className={!!error.sessionNameRequired ? 'input-error' : ''}
          />
        </div>
        <button onClick={joinSession} className='button-join'>
          Join
        </button>
        <span className='or-bubble'>OR</span>
        <div>
          <button onClick={createSession} className='button-create'>
            Create
          </button>
        </div>
      </div>
    </div>
  );
};
export default LandingPage;
