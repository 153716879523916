import React from 'react';

import { toggleHands } from '../../firebase/firestore';
import Attendee from './Attendee';

const SummaryCard = ({
  session,
  attendeesKeys,
  attendeesCount,
  observersCount,
  attendees,
  cardsChosen
}) => {
  const summary = () => {
    const totals = {};
    session.pointSystem.map((point) => (totals[point] = 0));

    attendeesKeys.map((key) => (totals[attendees[key].point] += 1));

    const headerRow = session.pointSystem.map((point, index) => (
      <th key={index}>{point}</th>
    ));
    const totalsRow = session.pointSystem.map((point, index) => (
      <td key={index}>{(session.displayHands && totals[point]) || 0}</td>
    ));

    return (
      <>
        <table className='points-summary'>
          <tbody>
            <tr key='header-row'>{headerRow}</tr>
            <tr key='totals-row'>{totalsRow}</tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr key='chosen-header-row'>
              <td key='header-name'>Name</td>
              <td key='header-ready'>Ready?</td>
            </tr>
          {cardsChosen.map(card => {
            const choseText = card.chosen ? 'Yes' : 'No';
            return (
              <tr key={`chosen-${card.name}`}>
                <td id='name'>{card.name}</td>
                <td id='chose'>{choseText}</td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </>
    );
  };

  function toggleHandsDisplay() {
    toggleHands(session, !session.displayHands);
  }

  function attendeeLabel() {
    if (attendeesCount === 0 || attendeesCount >= 2) {
      return <span>{attendeesCount} Participants</span>;
    } else {
      return <span>{attendeesCount} Participant</span>;
    }
  }

  function observerLabel() {
    if (observersCount === 0 || observersCount >= 2) {
      return <span>{observersCount} Observers</span>;
    } else {
      return <span>{observersCount} Observer</span>;
    }
  }

  return (
    <div className='flex-card summary-card'>
      <h4>Session Summary</h4>
      <div className='attendee-summary'>
        <span>{attendeeLabel()}</span>
        &nbsp;&nbsp;
        <span>{observerLabel()}</span>
      </div>
      <div className='points-results'>
        <h4>Points</h4>
        <ul>{summary()}</ul>
      </div>
      <div className='hands-options'>
        {!session.displayHands && (
          <button
            type='button'
            className='display-hands-button'
            onClick={toggleHandsDisplay}>
            Display Hands
          </button>
        )}
        {session.displayHands && (
          <button
            type='button'
            className='reset-hands-button'
            onClick={toggleHandsDisplay}>
            Reset Hands
          </button>
        )}
      </div>
    </div>
  );
};

const Body = ({ session, user, cardsChosen }) => {
  const attendees = session.attendees;
  const attendeesKeys = Object.keys(attendees);
  let observersCount = 0;
  let attendeesCount = 0;

  const attendeesList = attendeesKeys.map((attendeeId) => {
    const attendee = attendees[attendeeId];
    const style = {
      backgroundColor: attendee.color,
    };

    if (attendee.id === user.id && attendee.role === 'participant') {
      attendeesCount += 1;
    }

    if (attendee.role === 'participant' && attendee.id !== user.id) {
      attendeesCount += 1;
      return (
        <Attendee
          key={attendee.id}
          attendee={attendee}
          style={style}
          displayHand={session.displayHands}
        />
      );
    }

    if (attendee.role === 'observer') {
      observersCount += 1;
      return null;
    }

    return null;
  });

  return (
    <div className='body'>
      <SummaryCard
        session={session}
        attendeesKeys={attendeesKeys}
        attendees={attendees}
        attendeesCount={attendeesCount}
        observersCount={observersCount}
        cardsChosen={cardsChosen}
      />
      {user.role !== 'observer' && (
        <Attendee
          attendee={session.attendees[user.id]}
          style={{ backgroundColor: session.attendees[user.id].color }}
          displayHand={session.displayHands}
          isUser='true'
        />
      )}
      {attendeesList}
    </div>
  );
};
export default Body;
